var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { stringify } from "query-string";
import { fetchUtils } from "ra-core";
import { filterTransform } from "./utils";
/**
 * Maps react-admin queries to a REST API
 *
 * This REST dialect is similar to the one of FakeRest
 *
 * @see https://github.com/marmelab/FakeRest
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 * import {dataProvider} from 'ra-nest-rest';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={simpleRestProvider('http://path.to.my.api/')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 *
 *
 * //custom method for raw post, get
 * import {useDataProvider} from 'react-admin';
 * const Comp = () => {
  const dataProvider = useDataProvider();

 * dataProvider
    .customMethod("todos", { filter: { title: "title" } }, "GET")
    .then((result) => console.log(result))
    .catch((error) => console.error(error));

 * dataProvider
    .customMethod("todos", { data: { title: "title01" } }, "POST")
    .then((result) => console.log(result))
    .catch((error) => console.error(error));

  * show error message to client by return a promise reject
  return Promise.reject(new Error("get list fail"));
 */
export default (function (apiUrl, httpClient, countHeader) {
    if (httpClient === void 0) { httpClient = fetchUtils.fetchJson; }
    if (countHeader === void 0) { countHeader = "Content-Range"; }
    return ({
        getList: function (resource, params) {
            var token = localStorage.getItem("accessToken");
            var _a = params.pagination, page = _a.page, perPage = _a.perPage;
            var _b = params.sort, field = _b.field, order = _b.order;
            field = field == "id" ? "_id" : field;
            var rangeStart = (page - 1) * perPage;
            var rangeEnd = page * perPage - 1;
            var query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([rangeStart, rangeEnd]),
                filter: JSON.stringify(filterTransform(params.filter)),
            };
            var url = "".concat(apiUrl, "/").concat(resource, "?").concat(stringify(query));
            var options = countHeader === "Content-Range"
                ? {
                    // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
                    headers: new Headers({
                        Range: "".concat(resource, "=").concat(rangeStart, "-").concat(rangeEnd),
                        Authorization: "Bearer ".concat(token),
                    }),
                }
                : {};
            return httpClient(url, options).then(function (_a) {
                var headers = _a.headers, json = _a.json;
                if (!headers.has(countHeader)) {
                    throw new Error("The ".concat(countHeader, " header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ").concat(countHeader, " in the Access-Control-Expose-Headers header?"));
                }
                return {
                    data: json.map(function (resource) { return (__assign(__assign({}, resource), { id: resource._id })); }),
                    total: countHeader === "Content-Range"
                        ? parseInt(headers.get("content-range").split("/").pop(), 10)
                        : parseInt(headers.get(countHeader.toLowerCase())),
                };
            });
        },
        getOne: function (resource, params) {
            var token = localStorage.getItem("accessToken");
            var options = {
                headers: new Headers({
                    Authorization: "Bearer ".concat(token),
                }),
            };
            return httpClient("".concat(apiUrl, "/").concat(resource, "/").concat(params.id), options).then(function (_a) {
                var json = _a.json;
                return ({
                    data: __assign(__assign({}, json), { id: json._id }),
                });
            });
        },
        getMany: function (resource, params) {
            var token = localStorage.getItem("accessToken");
            var query = {
                filter: JSON.stringify({ _id: params.ids }),
            };
            var options = {
                headers: new Headers({
                    Authorization: "Bearer ".concat(token),
                }),
            };
            var url = "".concat(apiUrl, "/").concat(resource, "?").concat(stringify(query));
            return httpClient(url, options).then(function (_a) {
                var json = _a.json;
                return ({
                    data: json.map(function (resource) { return (__assign(__assign({}, resource), { id: resource._id })); }),
                });
            });
        },
        getManyReference: function (resource, params) {
            var _a;
            var token = localStorage.getItem("accessToken");
            var _b = params.pagination, page = _b.page, perPage = _b.perPage;
            var _c = params.sort, field = _c.field, order = _c.order;
            var rangeStart = (page - 1) * perPage;
            var rangeEnd = page * perPage - 1;
            var query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
                filter: JSON.stringify(__assign(__assign({}, params.filter), (_a = {}, _a[params.target] = params.id, _a))),
            };
            var url = "".concat(apiUrl, "/").concat(resource, "?").concat(stringify(query));
            var options = countHeader === "Content-Range"
                ? {
                    // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
                    headers: new Headers({
                        Range: "".concat(resource, "=").concat(rangeStart, "-").concat(rangeEnd),
                        Authorization: "Bearer ".concat(token),
                    }),
                }
                : {
                    headers: new Headers({
                        Authorization: "Bearer ".concat(token),
                    }),
                };
            return httpClient(url, options).then(function (_a) {
                var headers = _a.headers, json = _a.json;
                if (!headers.has(countHeader)) {
                    throw new Error("The ".concat(countHeader, " header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ").concat(countHeader, " in the Access-Control-Expose-Headers header?"));
                }
                return {
                    data: json.map(function (resource) { return (__assign(__assign({}, resource), { id: resource._id })); }),
                    total: countHeader === "Content-Range"
                        ? parseInt(headers.get("content-range").split("/").pop(), 10)
                        : parseInt(headers.get(countHeader.toLowerCase())),
                };
            });
        },
        update: function (resource, params) {
            var token = localStorage.getItem("accessToken");
            var options = {
                headers: new Headers({
                    Authorization: "Bearer ".concat(token),
                }),
            };
            return httpClient("".concat(apiUrl, "/").concat(resource, "/").concat(params.id), __assign({ method: "PUT", body: JSON.stringify(params.data) }, options)).then(function (_a) {
                var json = _a.json;
                return ({ data: __assign(__assign({}, json), { id: json._id }) });
            });
        },
        // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
        updateMany: function (resource, params) {
            var token = localStorage.getItem("accessToken");
            var options = {
                headers: new Headers({
                    Authorization: "Bearer ".concat(token),
                }),
            };
            return Promise.all(params.ids.map(function (id) {
                return httpClient("".concat(apiUrl, "/").concat(resource, "/").concat(id), __assign({ method: "PUT", body: JSON.stringify(params.data) }, options));
            })).then(function (responses) { return ({ data: responses.map(function (_a) {
                    var json = _a.json;
                    return json.id;
                }) }); });
        },
        create: function (resource, params) {
            var token = localStorage.getItem("accessToken");
            var options = {
                headers: new Headers({
                    Authorization: "Bearer ".concat(token),
                }),
            };
            return httpClient("".concat(apiUrl, "/").concat(resource), __assign({ method: "POST", body: JSON.stringify(params.data) }, options)).then(function (_a) {
                var json = _a.json;
                return ({ data: __assign(__assign({}, params.data), { id: json._id }) });
            });
        },
        delete: function (resource, params) {
            var token = localStorage.getItem("accessToken");
            return httpClient("".concat(apiUrl, "/").concat(resource, "/").concat(params.id), {
                method: "DELETE",
                headers: new Headers({
                    "Content-Type": "text/plain",
                    Authorization: "Bearer ".concat(token),
                }),
            }).then(function (_a) {
                var json = _a.json;
                return ({ data: __assign(__assign({}, json), { id: json._id }) });
            });
        },
        // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        deleteMany: function (resource, params) {
            var token = localStorage.getItem("accessToken");
            return Promise.all(params.ids.map(function (id) {
                return httpClient("".concat(apiUrl, "/").concat(resource, "/").concat(id), {
                    method: "DELETE",
                    headers: new Headers({
                        "Content-Type": "text/plain",
                        Authorization: "Bearer ".concat(token),
                    }),
                });
            })).then(function (responses) { return ({
                data: responses.map(function (_a) {
                    var json = _a.json;
                    return json.id;
                }),
            }); });
        },
        //custom method for raw get, post
        customMethod: function (resource, params, method) {
            var token = localStorage.getItem("accessToken");
            var options = {
                headers: new Headers({ Authorization: "Bearer ".concat(token) }),
            };
            if (method === "GET") {
                var query = {
                    filter: JSON.stringify(filterTransform(params.filter)),
                };
                var url = "".concat(apiUrl, "/").concat(resource, "?").concat(stringify(query));
                return httpClient(url, options).then(function (_a) {
                    var json = _a.json;
                    return ({
                        data: json,
                    });
                });
            }
            else if (method === "POST") {
                return httpClient("".concat(apiUrl, "/").concat(resource), __assign({ method: "POST", body: JSON.stringify(params.data) }, options)).then(function (_a) {
                    var json = _a.json;
                    return ({ data: params.data, result: json });
                });
            }
            else
                return Promise.reject("Method is not supported");
        },
    });
});
